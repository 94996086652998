import React, { useState } from 'react';
import { Layout } from '../components/layout/layout';
import { createUseStyles, useTheme } from 'react-jss';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import RehypeReact from 'rehype-react';
import Img from 'gatsby-image';
import { LeftRightButton } from '../components/utility/leftRightButton';
import { SnipcartButton } from '../components/shop/snipCartButton';
import { formatter } from '../utils/currencyFormatter';

const useStyles = createUseStyles((theme) => ({
  container: {
    display: 'flex',
    gap: '1vw',
    margin: ['3rem', 'auto'],
    maxWidth: 1800,
    width: '80vw'
  },
  mainPhotoContainer: {
    height: '100%',
    display: 'flex',
    flex: [[5, 0, 0]],
    overflow: 'hidden',
    position: 'relative',
    '&:hover button': {
      opacity: 0.5
    }
  },
  descriptionContainer: {
    flex: [[5, 0, 0]],
    padding: [['1rem']]
  },
  photoContainer: {
    paddingBottom: '100%',
    position: 'relative',
    width: '100%',
    overflow: 'hidden'
  },
  largePhoto: {
    flex: [[0, 0, '100%']],
    transition: 'left 0.2s ease-in-out'
  },
  photo: {
    width: '100%',
    height: '100%'
  },
  thumbnailGallery: {
    display: 'flex',
    flexDirection: 'column',
    flex: [[1, 0, 0]],
    minWidth: 50,
    gap: '1vw'
  },
  thumbnailContainer: {
    cursor: 'pointer'
  },
  button: {
    zIndex: 1,
    position: 'absolute',
    opacity: 0,
    top: 'calc(50% - 35px)',
    transition: 'opacity 0.3s ease-in'
  },
  productName: {},
  description: {
    marginTop: '3rem'
  },
  [`@media (max-width: ${theme.breakPoints.lgMax})`]: {
    container: {
      width: '90vw'
    }
  },
  [`@media (max-width: ${theme.breakPoints.mdMax})`]: {
    container: {
      width: '95vw'
    }
  },
  [`@media (max-width: ${theme.breakPoints.smMax})`]: {
    container: {
      flexDirection: 'column',
      width: '98vw'
    },
    mainPhotoContainer: {
      order: 1,
      flexBasis: '100%'
    },
    descriptionContainer: {
      order: 3
    },
    largePhoto: {
      height: '100%'
    },
    thumbnailGallery: {
      order: 2,
      flexDirection: 'row'
    },
    thumbnailContainer: {
      width: 'calc(20% - 1vw)'
    }
  },
  [`@media (max-width: ${theme.breakPoints.xsMax})`]: {
    thumbnailGallery: {
      display: 'none'
    }
  }
}));

export default function Product({
  pageContext: { name, slug, guid },
  data: {
    markdownRemark: {
      frontmatter: { price, photos },
      htmlAst
    }
  }
}) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const renderAst = new RehypeReact({
    createElement: React.createElement
  }).Compiler;

  const [currentImage, setCurrentImage] = useState(0);

  return (
    <Layout title={name} header={null} slug={slug} maxWidth={1200}>
      <div className={classes.container}>
        {photos[1] && (
          <div className={classes.thumbnailGallery}>
            {photos.map((p, i) => (
              <div
                className={classes.thumbnailContainer}
                onClick={() => setCurrentImage(i)}
                key={i}
                role="button"
              >
                <div className={classes.photoContainer}>
                  <Img
                    fluid={p.childImageSharp.fluid}
                    className={classes.photo}
                    style={{ position: 'absolute', top: 0, left: 0 }}
                    imgStyle={{ objectFit: 'contain' }}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        <div className={classes.mainPhotoContainer}>
          {photos.map((p, i) => (
            <div
              className={classNames(classes.photoContainer, classes.largePhoto)}
              style={{ left: currentImage * -100 + '%' }}
              key={i}
            >
              <Img
                fluid={p.childImageSharp.fluid}
                className={classes.photo}
                style={{ position: 'absolute', top: 0, left: 0 }}
                imgStyle={{ objectFit: 'contain' }}
              />
            </div>
          ))}
          {photos[1] && (
            <>
              {currentImage !== 0 && (
                <LeftRightButton
                  operation={() =>
                    setCurrentImage(
                      (i) => (i + photos.length - 1) % photos.length
                    )
                  }
                  direction="left"
                  className={classes.button}
                  style={{ left: 0 }}
                />
              )}
              {currentImage !== photos.length - 1 && (
                <LeftRightButton
                  operation={() => setCurrentImage((i) => ++i % photos.length)}
                  direction="right"
                  className={classes.button}
                  style={{ right: 0 }}
                />
              )}
            </>
          )}
        </div>
        <div className={classes.descriptionContainer}>
          <h1 className={classes.productName}>{name}</h1>
          <p>{`Cena: ${formatter.format(price)}`}</p>
          <SnipcartButton guid={guid} name={name} price={price} />
          <div className={classes.description}>{renderAst(htmlAst)}</div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query($guid: String!) {
    markdownRemark(
      frontmatter: { guid: { eq: $guid } }
      fields: { collection: { eq: "products" } }
    ) {
      frontmatter {
        guid
        name
        price
        photos {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      htmlAst
    }
  }
`;

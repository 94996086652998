import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';

const useStyles = createUseStyles({
  aspectRatio: {
    width: '100%',
    paddingBottom: '100%',
    position: 'relative'
  },
  button: ({ left }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    borderRadius: '50%',
    backgroundColor: '#FFFFFF',
    borderColor: '#AAAAAA',
    boxShadow: 'none',
    borderStyle: 'solid',
    borderWidth: 1,
    fontSize: '50%',
    lineHeight: 0,
    fontWeight: 200,
    cursor: 'pointer',
    '#___gatsby & svg': {
      width: '70%',
      height: '70%',
      position: 'relative',
      [left ? 'right' : 'left']: '5%'
    }
  })
});

export const LeftRightButton = ({ direction, operation, className, style }) => {
  // noinspection JSCheckFunctionSignatures
  const classes = useStyles({ left: direction === 'left' });

  return (
    <div className={classNames(classes.aspectRatio, className)}>
      <button
        style={style}
        className={classNames(classes.button)}
        onClick={operation}
      >
        {direction === 'left' ? <ChevronLeft /> : <ChevronRight />}
      </button>
    </div>
  );
};
